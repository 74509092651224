export const Medium: string[] = [
    'Facebook',
    'Instagram',
    'Folder',
    'Email',
    'Banner',
    'Plakat',
    'Inserat',
    'DirectMailing',
    'RollUp',
    'Visitenkarten',
    'Briefpapier',
    'Andere'
];