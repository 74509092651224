import {LabelValuePair} from "../Interfaces/LabelValuePair";

export const CornerTypes: LabelValuePair<string, string>[] = [
    {
        label: 'Eckig',
        value: 'square'
    },
    {
        label: 'Punkte',
        value: 'dot'
    },
    {
        label: 'Abgerundet',
        value: 'extra-rounded'
    }
];