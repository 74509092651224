import React, {Component, FormEvent, RefObject} from 'react';
import ColorPicker from "material-ui-color-picker";
import style from "./scss/App.module.scss";
import Dropzone from 'react-dropzone';
import {QRCode} from "./QRCode";
import Alert from '@material-ui/lab/Alert';
import {
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    TextField, Typography
} from "@material-ui/core";
import axios from "axios";
import {Medium} from "./Constants/Medium";
import {CornerTypes} from "./Constants/CornerTypes";
import {PixelTypes} from "./Constants/PixelTypes";

interface AppState {
    base: string,
    ref: string,
    source: string,
    medium: string,
    campaign: string,
    download: boolean,
    link: string,
    fgColor: string,
    bgColor: string,
    value: string,
    pixelType: string,
    cornerType: string,
    image: null | string,
    showAlert: boolean,
    alertType: 'error' | 'warning' | 'info' | 'success',
    alertContent: string
}

class App extends Component<{}, AppState> {
    state: AppState = {
        base: 'https://www.cic.at/',
        ref: '',
        source: '',
        medium: Medium[0],
        campaign: '',
        download: false,
        link: '',

        // qrcode settings
        fgColor: '#000000',
        bgColor: '#ffffff',
        value: 'https://link.cic.at/be3Igs',
        pixelType: 'rounded',
        cornerType: 'extra-rounded',
        image: null,

        // alert
        showAlert: false,
        alertType: 'success',
        alertContent: ''
    }

    qrcode: RefObject<any>;

    constructor(props: any) {
        super(props);

        this.qrcode = React.createRef();
        this.uploadLogo = this.uploadLogo.bind(this);
        this.generateShortenedLink = this.generateShortenedLink.bind(this);
        this.download = this.download.bind(this);
        this.checkDownload = this.checkDownload.bind(this);
        this.showAlert = this.showAlert.bind(this);
    }

    uploadLogo(files: File[]) {
        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                // @ts-ignore
                const binary: ArrayBuffer = reader.result;
                const base64 = 'data:image/png;base64,' + btoa((new Uint8Array(binary)).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                this.setState({image: base64});
            }

            reader.readAsArrayBuffer(file);
        })
    }

    generateShortenedLink(callback: (url: string) => void) {
        // parse url
        let parsed;
        try {
            parsed = new URL(this.state.base);
        } catch (e) {
            this.showAlert('Die angegebene URL ist ungültig.', 'error');
            return;
        }

        // set url params
        this.state.ref !== '' && parsed.searchParams.set('ref', this.state.ref);
        parsed.searchParams.set('utm_source', this.state.source);
        parsed.searchParams.set('utm_medium', this.state.medium);
        parsed.searchParams.set('utm_campaign', this.state.campaign);

        axios.post(
            process.env.REACT_APP_API_ENDPOINT || '',
            {
                longUrl: parsed.toString(),
                tags: [
                    'qr-code'
                ]
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        ).then(res => {
            callback(res.data.shortUrl);
        }).catch(err => {
            this.showAlert('Die angegebene URL ist nicht erreichbar.', 'error');
            console.log(err);
        });
    }

    download(e: FormEvent) {
        e.preventDefault();

        this.generateShortenedLink(link => {
            this.setState({
                value: link,
                download: true
            });
            this.showAlert('Der QR Code wurde erfolgreich generiert.');
        });
    }

    checkDownload(download: () => void) {
        if (this.state.download) {
            this.setState({download: false});
            download();
        }
    }

    showAlert(content: string, type: 'error' | 'warning' | 'info' | 'success' = 'success') {
        this.setState({
            showAlert: true,
            alertType: type,
            alertContent: content
        });

        setTimeout(() => {
            this.setState({
                showAlert: false,
            });
        }, 2000);
    }

    render() {
        return (
            <div className="app">
                <Container maxWidth="lg">
                    {this.state.showAlert ?
                        <Alert className={style.alert} severity={this.state.alertType}>{this.state.alertContent}</Alert>
                        : null
                    }

                    <Box p={4}>
                        <Box mb={3}>
                            <Typography align="center" variant="h4"><span className={style.cicLogo}>CIC</span> QR
                                Generator</Typography>
                        </Box>
                        <Grid container spacing={0} className={style.wrapper}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <form onSubmit={this.download} autoComplete="off">

                                        <Box mb={3}>
                                            <TextField
                                                id="link-input"
                                                label="Link"
                                                value={this.state.base}
                                                fullWidth
                                                required
                                                onChange={
                                                    (e) => this.setState({base: e.target.value})
                                                }
                                            />
                                        </Box>

                                        <Box mb={3}>
                                            <TextField
                                                id="ref-input"
                                                label="Ref-Code"
                                                value={this.state.ref}
                                                fullWidth
                                                onChange={
                                                    (e) => this.setState({ref: e.target.value})
                                                }
                                            />
                                        </Box>

                                        <Box mb={3}>
                                            <TextField
                                                id="source-input"
                                                label="Quelle"
                                                helperText="Der Werbetreibende, die Website oder die Publikation, über die Zugriffe erfolgen."
                                                value={this.state.source}
                                                fullWidth
                                                required
                                                onChange={
                                                    (e) => this.setState({source: e.target.value})
                                                }
                                            />
                                        </Box>

                                        <Box mb={3}>
                                            <TextField
                                                id="medium-input"
                                                label="Medium"
                                                helperText="Das Werbe- oder Marketingmedium, z.B. Social Media, Banner oder E-Mail-Newsletter."
                                                name="medium"
                                                value={this.state.medium}
                                                select
                                                fullWidth
                                                required
                                                onChange={
                                                    (e) => this.setState({medium: e.target.value})
                                                }
                                            >
                                                {Medium.map((option) => (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                ))};
                                            </TextField>
                                        </Box>

                                        <Box mb={3}>
                                            <TextField
                                                id="campaign-input"
                                                label="Kampagne"
                                                helperText="Beispielsweise der Kampagnenname, Slogan oder Gutscheincode für ein Produkt."
                                                name="campaign"
                                                value={this.state.campaign}
                                                fullWidth
                                                required
                                                inputProps={{maxLength: 32}}
                                                onChange={
                                                    (e) => this.setState({campaign: e.target.value})
                                                }
                                            />
                                        </Box>

                                        <Box mb={3}>
                                            <TextField
                                                id="pixel-type-input"
                                                label="Ecken"
                                                name="pixel"
                                                value={this.state.cornerType}
                                                select
                                                fullWidth
                                                required
                                                onChange={
                                                    (e) => this.setState({cornerType: e.target.value})
                                                }
                                            >
                                                {CornerTypes.map((option) => (
                                                    <MenuItem key={option.value}
                                                              value={option.value}>{option.label}</MenuItem>
                                                ))};
                                            </TextField>
                                        </Box>

                                        <Box mb={3}>
                                            <TextField
                                                id="pixel-type-input"
                                                label="Pixel"
                                                name="pixel"
                                                value={this.state.pixelType}
                                                select
                                                fullWidth
                                                required
                                                onChange={
                                                    (e) => this.setState({pixelType: e.target.value})
                                                }
                                            >
                                                {PixelTypes.map((option) => (
                                                    <MenuItem key={option.value}
                                                              value={option.value}>{option.label}</MenuItem>
                                                ))};
                                            </TextField>
                                        </Box>

                                        <Box mb={3}>
                                            <ColorPicker
                                                fullWidth
                                                label="Vordergrundfarbe"
                                                InputProps={{value: this.state.fgColor}}
                                                className={style.colorInput}
                                                required
                                                onChange={color => {
                                                    if (!color) {
                                                        return;
                                                    }

                                                    this.setState({fgColor: color});
                                                }}
                                            />
                                        </Box>

                                        <Box mb={3}>
                                            <ColorPicker
                                                fullWidth
                                                InputProps={{value: this.state.bgColor}}
                                                label="Hintergrundfarbe"
                                                className={style.colorInput}
                                                required
                                                onChange={color => {
                                                    if (!color) {
                                                        return;
                                                    }

                                                    this.setState({bgColor: color});
                                                }}
                                            />
                                        </Box>

                                        <Box mb={3}>
                                            <Grid container>
                                                <Dropzone onDrop={this.uploadLogo} maxFiles={1}
                                                          accept={["image/png", "image/jpg", "image/jpeg"]}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <Grid item xs={12} md={6}>
                                                            <Box my={1} textAlign="center">
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <Button variant="outlined" color="primary">Logo
                                                                        hochladen</Button>
                                                                </div>
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                </Dropzone>

                                                <Grid item xs={12} md={6}>
                                                    <Box my={1} textAlign="center">
                                                        <Button variant="outlined" color="secondary"
                                                                onClick={() => this.setState({image: null})}>Logo
                                                            entfernen</Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box textAlign="center">
                                            <Button variant="contained" color="primary" type="submit">Speichern</Button>
                                        </Box>
                                    </form>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box className={style.codeWrapper}>
                                    <QRCode
                                        data={this.state.value}

                                        image={this.state.image}

                                        cornersColor={this.state.fgColor}
                                        cornersType={this.state.cornerType}

                                        dotsColor={this.state.fgColor}
                                        dotsType={this.state.pixelType}

                                        backgroundColor={this.state.bgColor}

                                        onChange={this.checkDownload}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>
        );
    }
}

export default App;
