import {LabelValuePair} from "../Interfaces/LabelValuePair";

export const PixelTypes: LabelValuePair<string, string>[] = [
    {
        label: 'Eckig',
        value: 'square'
    },
    {
        label: 'Punkte',
        value: 'dots'
    },
    {
        label: 'Abgerundet',
        value: 'rounded'
    },
    {
        label: 'Stark abgerunded',
        value: 'extra-rounded'
    }
];