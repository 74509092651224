import React, {Component, Ref} from "react";
import QRCodeStyling from "qr-code-styling";

interface QRCodeProps {
    size?: number,
    data: string,
    margin?: number,

    // gradient
    gradientType?: 'linear' | 'radial',
    gradientRotation?: number,

    // image
    image?: null|string,
    imageSize?: number,
    imageMargin?: number,

    // dots
    dotsColorType?: 'color' | 'gradient',
    dotsColor?: string,
    dotsType?: string | 'rounded' | 'dots' | 'classy' | 'classy-rounded' | 'square' | 'extra-rounded',
    dotsGradient?: { from: string, to: string }

    // corners
    cornersColorType?: 'color' | 'gradient',
    cornersColor?: string,
    cornersType?: string | 'dot' |'square' | 'extra-rounded',
    cornersGradient?: { from: string, to: string },

    // background
    backgroundColorType?: 'color' | 'gradient',
    backgroundColor?: string,
    backgroundGradient?: { from: string, to: string }

    // callbacks
    onChange?: (download: () => void) => void
}

export class QRCode extends Component<QRCodeProps, {}> {
    drawingCanvas: Ref<HTMLDivElement>
    qrCode: QRCodeStyling

    constructor(props: any) {
        super(props);

        this.drawingCanvas = React.createRef();
        this.qrCode = new QRCodeStyling(this.getOptions());
    }

    componentDidMount(): void {
        // @ts-ignore
        this.qrCode.append(this.drawingCanvas.current);
    }

    componentDidUpdate(): void {
        this.qrCode.update(this.getOptions());

        setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange(() => {
                    this.qrCode.download({
                        name: 'code-' + Date.now(),
                        extension: 'svg'
                    });
                });
            }
        }, 500);
    }

    getOptions(): object {
        return {
            margin: this.props.margin || 0,
            width: this.props.size || 500,
            height: this.props.size || 500,
            type: "canvas",
            data: this.props.data,
            dotsOptions: this.getDotOptions(),
            cornersSquareOptions: this.getCornerOptions(),
            cornersDotOptions: {
                type: this.props.cornersType === 'dot' || this.props.cornersType === 'extra-rounded' ? 'dot' : 'square'
            },
            backgroundOptions: this.getBackgroundOptions(),
            ...this.getImageOptions()
        };
    }

    getDotOptions(): object {
        switch (this.props.dotsColorType || 'color') {
            case "color":
                return {
                    color: this.props.dotsColor || 'rgba(0,0,0,1)',
                    type: this.props.dotsType || 'square'
                }
            case "gradient":
                return {
                    gradient: {
                        colorStops: [
                            {
                                offset: 0,
                                color: this.props.dotsGradient?.from || 'rgba(0,0,0,0)'
                            },
                            {
                                offset: 1,
                                color: this.props.dotsGradient?.to || 'rgba(0,0,0,1)'
                            }
                        ],
                        type: this.props.gradientType || 'radial',
                        rotation: this.props.gradientRotation || 0,
                    },
                    type: this.props.dotsType || 'square'
                }
        }
    }

    getCornerOptions(): object {
        switch (this.props.cornersColorType || 'color') {
            case "color":
                return {
                    color: this.props.cornersColor || 'rgba(0,0,0,1)',
                    type: this.props.cornersType || 'square'
                }
            case "gradient":
                return {
                    gradient: {
                        colorStops: [
                            {
                                offset: 0,
                                color: this.props.cornersGradient?.from || 'rgba(0,0,0,0)'
                            },
                            {
                                offset: 1,
                                color: this.props.cornersGradient?.to || 'rgba(0,0,0,1)'
                            }
                        ],
                        type: this.props.gradientType || 'radial',
                        rotation: this.props.gradientRotation || 0,
                    },
                    type: this.props.cornersType || 'square'
                }
        }
    }

    getBackgroundOptions(): object {
        switch (this.props.backgroundColorType || 'color') {
            case "color":
                return {
                    color: this.props.backgroundColor || 'rgba(0,0,0,0)'
                }
            case "gradient":
                return {
                    gradient: {
                        colorStops: [
                            {
                                offset: 0,
                                color: this.props.backgroundGradient?.from || 'rgba(0,0,0,0)'
                            },
                            {
                                offset: 1,
                                color: this.props.backgroundGradient?.to || 'rgba(0,0,0,1)'
                            }
                        ],
                        type: this.props.gradientType || 'radial',
                        rotation: this.props.gradientRotation || 0,
                    }
                }
        }
    }

    getImageOptions(): object {
        if (this.props.image) {
            return {
                image: this.props.image,
                imageOptions: {
                    imageSize: this.props.imageSize || 0.4,
                    margin: this.props.imageMargin || 10
                }
            };
        } else {
            return {
                imageOptions: {
                    imageSize: 0,
                    margin: 0
                }
            };
        }
    }

    render() {
        return (
            <>
                <div ref={this.drawingCanvas}/>
            </>
        );
    }
}